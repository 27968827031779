.contact {
  &__status {
    color: $error;
  }

  &__status.is-success {
    color: $success;
  }

  &__input-name,
  &__input-email,
  &__input-message {
    padding: calc($item-padding / 2);
  }

  &__input-name,
  &__input-email {
    width: 50%;
  }

  &__input-message {
    width: 100%;
  }

  &__status {
    width: 100%;
    min-height: 2.5rem;
    padding: calc($item-padding / 2);
    text-align: center;
  }

  &__btn-container {
    width: 100%;
    text-align: center;
  }

  &__btn {
    @include btn;
    cursor: pointer;
  }
}
