// Set up the box sizing override
// --------------------------------------------------
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// Set the following tags to scale to their container size
// --------------------------------------------------
img,
object,
embed {
  max-width: 100%;
}

// Override the default 'inline' display property for images
// --------------------------------------------------
img {
  display: block;
  min-width: 100%;
}

// Remove the default border and padding from all button tags
// --------------------------------------------------
button {
  border: 0;
  padding: 0;
}

// Add a pointer to button tags that are not disabled or locked
// --------------------------------------------------
button {
  cursor: pointer;
}
