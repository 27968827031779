.btn-text {
  @include btn-text;
  margin-top: $btn-margin;
  padding: $btn-padding-ver $btn-padding-hoz;
}

@mixin btn() {
  background-color: transparent;
  border: 0;
  border-radius: 50px;
  box-shadow: 0 0 0 1px $white inset;
  color: $white;

  .no-touch &:hover {
    background-color: $white;
    color: $grey-dark;
  }

  .no-touch:not(.no-csstransitions) &:hover {
    transition: background-color $duration ease-in, color $duration ease-in;
  }
}
