// font import
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

// font definitions
$title-family: 'Open Sans', Helvetica, Arial, sans-serif;
$body-family: 'Open Sans', Helvetica, Arial, sans-serif;
$btn-family: 'Open Sans', Helvetica, Arial, sans-serif;

// font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

// line height
$title-line-height: 1.2;
$body-line-height: 1.5;
$btn-line-height: 1.5;

// globals
$body-size: 1rem;
$body-weight: $font-weight-regular;

$btn-size: $body-size;
$btn-weight: $font-weight-regular;

// nav
$nav-title-size: 1.5rem;
$nav-title-family: $title-family;
$nav-title-weight: $font-weight-light;
$nav-title-line-height: $title-line-height;
$nav-title-mobile-percentage: .75;

body {
  font-family: $body-family;
  font-size: $body-size;
  font-weight: $body-weight;
  line-height: $body-line-height;
  color: $font-color;
}

@mixin btn-text() {
  font-family: $btn-family;
  font-size: $btn-size;
  font-weight: $font-weight-regular;
  line-height: $btn-line-height;
}

@mixin nav-title() {
  font-size: $nav-title-size * $nav-title-mobile-percentage;
  font-family: $nav-title-family;
  font-weight: $nav-title-weight;
  line-height: $nav-title-line-height;

  @media (min-width: $device-sm) {
    font-size: $nav-title-size;
  }
}
