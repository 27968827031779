.footer {
  background-color: $grey-dark;
  color: $font-color-inverted;

  &__inner {
    @include l-container($device-sm);
    @include l-padding($footer-padding-ver, $footer-padding-hoz);
  }

  &__copyright,
  &__disclaimer {
    margin-top: $item-margin;
    font-size: $body-size * .75;
    text-align: center;
  }
}
