.nav {
  background-color: $grey-dark;
  text-align: center;

  &__inner {
    @include l-container($device-md);
    @include l-padding($nav-padding-ver, $nav-padding-hoz);
  }

  &__link {
    display: inline-block;
    text-decoration: none;
    color: $font-color-inverted;
    @include nav-title;
    letter-spacing: 0.625em;
    text-transform: uppercase;
  }
}
