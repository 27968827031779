.list-image {
  &__inner {
    @include l-container($device-md);
    @include l-padding($component-padding-ver, $component-padding-hoz);
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    width: 100%;
    padding: $item-padding;

    @media (min-width: $device-sm) {
      width: 50%;
    }
  }

  &.items-are-full-width &__item {
    @media (min-width: $device-sm) {
      width: 100%;
    }
  }

  &.items-are-third-width &__item {
    @media (min-width: $device-sm) {
      width: 33.33333%;
    }
  }
}
