.header {
  background-color: $blue;
  color: $font-color-inverted;
  text-align: center;

  &__inner {
    @include l-container($device-md);
    @include l-padding($header-padding-ver, $header-padding-hoz);
  }

  &__heading {
    margin: 0;
  }

  &__body {
    margin-top: $item-margin;
  }
}
